import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/non-profits.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Non-Profits | Camelo"
        metaDescription="A complete scheduling toolkit for non-profits: organize staff shifts, view staff availability, & send leave requests in only one app—Camelo."
        header="An all-in-one scheduling app for non-profits."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
